const setRegister = (bool) => {
  localStorage.setItem("isRegister", bool);
};

const setVoucher = (data) => {
  localStorage.setItem("voucher", data);
};

const getVoucher = () => {
  return localStorage.getItem("voucher");
};

const setVoucherStatus = (bool) => {
  localStorage.setItem("isVoucher", bool);
};

const setCertStatus = (bool) => {
  localStorage.setItem("cert_details", bool);
};

const setReturnStatus = (bool) => {
  localStorage.setItem("returnpage", bool);
};

const getReturnStatus = () => {
  return localStorage.getItem("returnpage");
};

const setRegisterStatus = (bool) => {
  localStorage.setItem("isRegister", bool);
};

const getRegisterStatus = () => {
  return localStorage.getItem("isRegister");
};

const setUserformStatus = (bool) => {
  localStorage.setItem("userform", bool);
};

const getUserformStatus = () => {
  return localStorage.getItem("userform");
};

const setDenomination = (data) => {
  localStorage.setItem("denomination", data);
};

const getDenomination = () => {
  return localStorage.getItem("denomination");
};

const getPage = () => {
  return localStorage.getItem("page");
};

const setPage = (data) => {
  localStorage.setItem("page", data);
};

const removePage = () => {
  localStorage.removeItem("page");
};

const setAns = (ans, data) => {
  localStorage.setItem(ans, data);
};

const getAns = (ans) => {
  return localStorage.getItem(ans);
};

const setCertDetail = (bool) => {
  localStorage.setItem("cert_details", bool);
};

const setThankStatus = (bool) => {
  localStorage.setItem("Thankyou", bool);
};

const setFormStatus = (bool) => {
  localStorage.setItem("userformopen", bool);
};

const getFormStatus = () => {
  return localStorage.getItem("userformopen");
};

const getThankStatus = () => {
  return localStorage.getItem("Thankyou");
};

const getCertDetail = () => {
  return localStorage.getItem("cert_details");
};

const destroyStorage = () => {
  // localStorage.removeItem("voucher");
  // localStorage.removeItem("page");
  // localStorage.removeItem("ans1");
  // localStorage.removeItem("ans2");
  // localStorage.removeItem("ans3");
  localStorage.clear();
  window.location.replace("/");
};
const destroyRedemptiontorage = () => {
  localStorage.clear();
};


const destroyRedThankStorage = () => {
  localStorage.removeItem("returnthankyou");
  localStorage.removeItem("voucher");
  localStorage.removeItem("returnpage");
  localStorage.removeItem("userformopen");
  localStorage.removeItem("userform");
};

const destroyThankStorage = () => {
  localStorage.removeItem("TestOurTech_thankyou");
  localStorage.removeItem("returnTestOurTech_thankyou");
  localStorage.removeItem("registerForm");
  localStorage.removeItem("returnpage");
};

const destroyVoucherInfo = () => {
  localStorage.setItem("returnpage", false);
  localStorage.setItem("userform", false);
  localStorage.removeItem("page");
  localStorage.removeItem("ans1");
  localStorage.removeItem("ans2");
};
const destroyVoucherInfo1 = () => {
  localStorage.setItem("returnpage", false);
  localStorage.setItem("userform", false);
  localStorage.setItem("userformopen", false);
  localStorage.removeItem("page");
  localStorage.removeItem("ans1");
  localStorage.removeItem("ans2");
};
const destroyTimeout = () => {
  localStorage.setItem("returnpage", false);
  localStorage.removeItem("userform");
  localStorage.removeItem("userformopen");
  localStorage.removeItem("voucher");
  localStorage.removeItem("page");
  localStorage.removeItem("ans1");
  localStorage.removeItem("ans2");
  
};

const setUserformStorage = () => {
  localStorage.setItem("isVoucher", true);
  localStorage.setItem("returnpage", false);
  localStorage.setItem("userformopen", false);
  localStorage.setItem("userform", false);
  localStorage.setItem("Thankyou", true);
  localStorage.removeItem("ans1");
  localStorage.removeItem("ans2");
};

const StorageService = {
  setRegister,
  setVoucherStatus,
  setDenomination,
  setVoucher,
  setCertStatus,
  getReturnStatus,
  setReturnStatus,
  setUserformStatus,
  getPage,
  removePage,
  setPage,
  setAns,
  destroyStorage,
  destroyRedemptiontorage,
  setCertDetail,
  getCertDetail,
  setRegisterStatus,
  getRegisterStatus,
  destroyThankStorage,
  destroyRedThankStorage,
  setThankStatus,
  getThankStatus,
  getVoucher,
  setFormStatus,
  getUserformStatus,
  destroyVoucherInfo,
  destroyVoucherInfo1,
  destroyTimeout,
  getFormStatus,
  getDenomination,
  getAns,
  setUserformStorage,
};

export default StorageService;
