import React from "react";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Swal from "sweetalert2";
import $ from "jquery";
import { useSelector } from "react-redux";

export default function ManagePavlina() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const pavList = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listPavlina(page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            AdminId: value.AdminId,
            AdminName: value.AdminName,
            Email: value.Email,
            UserName: value.UserName,
            Password: value.Password,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      pavList("1");
    }
  }, [adminAccessToken]);

  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  };

  const delPav = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deletePav(delId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              pavList("1");
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const delSelected = async () => {
    var selectedRecords = await $(
      ".sort-ths tbody tr td input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Please select some record!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete record",
        icon: "question",
        iconColor: "#e04f5d",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",

        confirmButtonText: "Yes",
        cancelButtonText: "Close",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);

          try {
            const response = await AdminListService.deletSelectedPav(
              selectedRecords
            );
            if (response.status === 200) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });

              setTimeout(() => {
                pavList("1");
              }, 1500);
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              confirmButtonColor: "#e72e2e",
              text: "Something went wrong!",
            });
            setLoading(false);
          }
        }
      });
    }
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.AdminName.toString().toLowerCase().includes(searchQuery) ||
          item.Password.toString().toLowerCase().includes(searchQuery) ||
          item.UserName.toString().toLowerCase().includes(searchQuery) ||
          item.Email.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                <input
                  className="form-check-input slect_all"
                  type="checkbox"
                  value=""
                  onChange={allSelect}
                />
              </th>
              <th
                scope="col"
                onClick={() => requestSort("UserName")}
                className={getClassNamesFor("UserName")}
              >
                User Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Password")}
                className={getClassNamesFor("Password")}
              >
                Password
              </th>
              <th
                scope="col"
                onClick={() => requestSort("AdminName")}
                className={getClassNamesFor("AdminName")}
              >
                Pavlina Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("Email")}
                className={getClassNamesFor("Email")}
              >
                Email
              </th>

              <th
                scope="col"
                onClick={() => requestSort("Active")}
                className={getClassNamesFor("Active")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={el.AdminId}
                      value={el.AdminId}
                    />
                  </td>
                  <td>{el.UserName}</td>
                  <td>{el.Password}</td>
                  <td>{el.AdminName}</td>
                  <td>{el.Email}</td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="text-warning"
                        to={"/Editpav/" + el.AdminId}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                      <button
                        type="button"
                        onClick={() => {
                          delPav(el.AdminId);
                        }}
                        className="text-danger open-model-btn"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Manage Pavlina
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  Back
                </button>
              </h5>
            </div>

            <div className="card-body">
              <Link to="/Addpav" className="btn btn-primary btn-sm ">
                <i className="bi bi-plus-circle-fill"></i> Add Pavlina
              </Link>
              <button
                className="btn btn-primary btn-sm ms-2"
                onClick={delSelected}
              >
                <i className="bi bi-trash-fill"></i> Delete Selected Pavlina
              </button>

              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>

              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
