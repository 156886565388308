import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import AdminListService from "../../../services/admin-list.service";

import ToTop from "../includes/ToTop";
import { useSelector } from "react-redux";

export default function VirtualCard() {
  const state = useSelector((state) => state.stateVals);

  const { pavlina_id } = state;
  const [loading, setLoading] = useState(false);

  const [CertifecateRecordData, setCertifecateRecordData] = useState([]);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const selectKey = useRef(null);

  const virtualCards = async (pageNo) => {
    setLoading(true);
    try {
      const responce = await AdminListService.cardVirtual(pageNo);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pavlina_id) {
      virtualCards("1");
    }
  }, [pavlina_id]);

  const customSearchRecord = async (e) => {
    setLoading(true);
    const key = await selectKey.current.value;
    const keyVal = e.target.value;
    try {
      const responce = await AdminListService.searchCustomRecord(key, keyVal);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const CertificateSearchRecords = ({ allData }) => {
    return (
      <>
        <>
          <div className="row">
            <div className="col-md-8 col-lg-4 ms-auto">
              <div className="input-group mt-2 mb-2">
                <select className="form-select" ref={selectKey}>
                  <option value="all">All</option>
                  <option value="FIRST_NAME">First Name</option>
                  <option value="LAST_NAME">Last Name</option>
                  <option value="PHONE_NUMBER">Phone Number</option>
                  <option value="EmailAddress">Email</option>
                  <option value="ADDRESS">Address</option>
                  <option value="CITY">City</option>
                  <option value="STATE">State</option>
                  <option value="ZIP">Zip</option>
                  <option value="DENOMINATION">Denomination</option>
                  <option value="CUSTOMER">Company</option>
                  <option value="CERT_NUMBER">Cert Number</option>
                  <option value="REDEMPTION_BEINS">Begin Redemption</option>
                  <option value="REDEMPTION_ENDS">End Redemption</option>
                  <option value="JOB_NUMBER">Job Number</option>
                  <option value="CERT_STATUS">Cert Status</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={customSearchRecord}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive mt-3">
            <table className="table table-striped table-bordered W-100  align-middle">
              <thead>
                <tr>
                  <th>
                    <input class="form-check-input" type="checkbox" />
                  </th>

                  <th>User Info</th>
                  <th>Certificate Info</th>
                </tr>
              </thead>
              <tbody>
                {allData.length ? (
                  allData.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <input class="form-check-input" type="checkbox" />
                      </td>
                      <td>
                        <div>
                          First Name: <strong> {el.FirstName} </strong>{" "}
                        </div>
                        <div>
                          Last Name: <strong> {el.LastName} </strong>
                        </div>
                        <div>
                          Phone Number: <strong> {el.PhoneNumber} </strong>
                        </div>
                        <div>
                          Email: <strong> {el.Email} </strong>
                        </div>
                        <div>
                          Address: <strong> {el.Address} </strong>
                        </div>
                        <div>
                          City: <strong> {el.City} </strong>
                        </div>
                        <div>
                          State: <strong> {el.State} </strong>
                        </div>
                        <div>
                          Zip: <strong> {el.ZipCode} </strong>
                        </div>
                        <div>
                          Denomination: <strong> {el.Denomination} </strong>
                        </div>
                        <div>
                          Company: <strong> {el.company} </strong>
                        </div>
                      </td>
                      <td>
                        <div>
                          Cert Number: <strong> {el.certNo} </strong>
                        </div>
                        <div>
                          Random Certificate: <strong> {el.randomCert} </strong>
                        </div>
                        <div>
                          Begin Redemption: <strong> {el.redBegin} </strong>
                        </div>
                        <div>
                          End Redemption: <strong>{el.redEnd} </strong>
                        </div>
                        <div>
                          Job Number: <strong>{el.jobNo} </strong>
                        </div>
                        <div>
                          Cert Status: <strong> {el.certStatus} </strong>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" align="center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                className="justify-content-center"
                onChange={(e) => {
                  virtualCards(e);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </div>
        </>
      </>
    );
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} Pavlina Admin</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">Certificate</h5>
          </div>

          <div className="card-body">
            <button href="" className="btn btn-primary mt-2 btn-sm ">
              <i className="bi bi-cloud-download-fill"></i> Export Selected{" "}
            </button>
            <a
              href={process.env.REACT_APP_API_Link + "exportAllVirtual"}
              className="btn btn-primary mt-2 btn-sm ms-2"
            >
              <i className="bi bi-cloud-download-fill"></i> Export All
            </a>
            <CertificateSearchRecords allData={CertifecateRecordData} />
          </div>
        </div>

        <div
          className="card search-certificate-result"
          style={{ display: "none" }}
        >
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">Certificate</h5>
          </div>

          <div className="card-body">
            <div className="table-responsive mt-3">
              <table
                id="example"
                className="table table-striped table-bordered dataTable no-footer "
              >
                <thead>
                  <tr>
                    <th className="font-24">User Name</th>
                    <th className="font-24">Export Date Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ashley Furniture</td>
                    <td>2021-11-02 03:11:23</td>
                  </tr>
                  <tr>
                    <td>Ashley Furniture old</td>
                    <td>2020-11-02 03:11:23</td>
                  </tr>
                  <tr>
                    <td>Ashley Furniture</td>
                    <td>2021-11-02 03:11:23</td>
                  </tr>
                  <tr>
                    <td>Ashley Furniture old</td>
                    <td>2020-11-02 03:11:23</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
