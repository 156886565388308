import React from "react";
import cityMark from "../Images/city_mark.png";

function ContactUs() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityMark} alt="city-mark.png" width="55" height="55" />
      </div>

      <div className="col-md-11">
        If you have any questions, the fastest service contact is to email{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_DONORCARE}>
          {" "}
          {process.env.REACT_APP_DESC_DONORCARE}
        </a>
        <br />
        Phone assistance is also available{" "}
        <a href={"tel:" + process.env.REACT_APP_DESC_CONTACTUS}>
          {process.env.REACT_APP_DESC_CONTACTUS}
        </a>
        <br />
        Our business hours are Monday - Friday 8:00 AM - 7:00 PM EST.
      </div>
    </div>
  );
}
export default ContactUs;
