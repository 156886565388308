import React, { useEffect, useReducer, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";
import "./UserFormCards.css";
import HeaderLower from "./HeaderLower";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Model from "./Model";
import UserformService from "../services/userform.service";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";

import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, data: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

function UserForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Customer User Form ";

  const [{ loading, error, data }, dispatch] = useReducer(reducer, {
    data: [],
    loading: true,
    error: "",
  });

  const [fields, setFields] = useState({
    name: "",
    lname: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",
    phonenumber1: "",
    phonenumber2: "",
    emailid: "",
  });

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [value_three, setValue_three] = useState([]);
  const [i_agree, setI_agree] = useState(false);
  const [i_agree_five, setI_agree_five] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [popup, setPopup] = useState("true");
  const [selectCard, setSelectCard] = useState("");
  const [valueUserform, setValueUserform] = useState("");
  const [imageInfo, setImageInfo] = useState({
    id: "",
    name: "",
    image: "props.cardImage",
  });
  const navigate = useNavigate();
  const ref = useRef();
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/user-form");
  if (localStorage.getItem("cardIndex") === "ture") {
    localStorage.setItem("userform", true);
  }
  const open = localStorage.getItem("userform");
  const Voucher = localStorage.getItem("voucher");
  const card = localStorage.getItem("CardIndex");

  useEffect(() => {
    async function getStates() {
      let errors = {};

      let bodyFormData = new FormData();
      bodyFormData.append("certificate", localStorage.getItem("voucher"));

      // bodyFormData.append("certificate", StorageService.getVoucher());
      try {
        const response = await UserformService.getStates();
        console.log(response);
        var states = response.data.data;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.state_name,
          });
        });
        await setStates([...results]);
        // setStates(states);
        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    async function getCards() {
      dispatch({ type: "FETCH_REQUEST" });

      let errors = {};

      let bodyFormData = new FormData();
      bodyFormData.append("certificate", localStorage.getItem("voucher"));
      // bodyFormData.append("certificate", StorageService.getVoucher());

      try {
        const response_sheet = await UserformService.getCards(bodyFormData);
        const value_three = response_sheet.data.data;
        setValue_three(value_three);

        errors["voucher"] = response_sheet.data.response;
        dispatch({ type: "FETCH_SUCCESS", payload: value_three });
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        } else if (err.message.includes("400")) {
          dispatch({
            type: "FETCH_FAIL",
            payload: "Failed to load cards",
          });
        } else {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      }
    }

    if (
      Voucher === undefined ||
      Voucher === null ||
      card === undefined ||
      card === null
    ) {
      localStorage.setItem("returnpage", false);
      localStorage.removeItem("page");
      localStorage.removeItem("ans1");
      localStorage.removeItem("ans2");
      localStorage.removeItem("ans3");
      localStorage.removeItem("card_id");
      localStorage.removeItem("card_name");
      localStorage.removeItem("card_image");
      localStorage.setItem("userformopen", false);
      localStorage.setItem("userform", false);
      navigate("/");
    } else {
      getStates();
      getCards();
      setImageInfo((prevData) => {
        return {
          ...prevData,
          id: localStorage.getItem("card_id"),
          name: localStorage.getItem("card_name"),
          image: localStorage.getItem("card_image"),
          type: localStorage.getItem("card_type"),
        };
      });
    }
  }, []);

  useEffect(() => {
    function timeout() {
      setTimeout(function () {
        StorageService.destroyTimeout();

        window.location.replace("/");

        return false;
      }, signoutTime);
    }

    timeout();
  }, [signoutTime]);

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };
  const moveOnMax = (field, nextFieldID) => {
    if (field.target.value.length >= field.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChangecheckboxfive = (e, value) => {
    let cardName = e.target.value;
    setSelectCard(cardName);
    setI_agree_five(!i_agree_five);
  };
  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const handleClick = (e) => {
    let errors = {};

    if (i_agree === false) {
      e.preventDefault();
      errors["agree"] = "Please accept terms & condition.";
      setErrors(errors);
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let fields = {};
      fields["voucher"] = "";
      fields["name"] = "";
      fields["lname"] = "";
      fields["address"] = "";
      fields["city"] = "";
      fields["state"] = "";
      fields["zipcode"] = "";
      fields["phonenumber"] = "";
      fields["phonenumber1"] = "";
      fields["phonenumber2"] = "";
      fields["emailid"] = "";
      fields["checkederror"] = "";
      fields["checkederrortwo"] = "";
      fields["checkederrorfive"] = "";
      fields["checkedboxerrornine"] = "";

      setFields({});
      if (fields["name"] === "") {
        if (i_agree) {
          secureLocalStorage.setItem(
            "UserForm",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phonenumber: fields.phonenumber,
              phonenumber1: fields.phonenumber1,
              phonenumber2: fields.phonenumber2,
              emailid: fields.emailid,
              card: selectCard,
              popup: popup,
            })
          );
          navigate("/redemption-thankyou");
          // props.history.push({
          //   pathname: "/Thankyou",
          //   state: {
          //     name: fields.name,
          //     lname: fields.lname,
          //     address: fields.address,
          //     city: fields.city,
          //     state: fields.state,
          //     zipcode: fields.zipcode,
          //     phonenumber: fields.phonenumber,
          //     phonenumber1: fields.phonenumber1,
          //     phonenumber2: fields.phonenumber2,
          //     emailid: fields.emailid,
          //     card: selectCard,
          //     popup: popup,
          //   },
          // });
        }
      }
    }
  };

  const validate = (e) => {
    let isformIsValid = false;
    let data = {
      fname: fields.name,
      lname: fields.lname,
      address: fields.address,
      city: fields.city,
      state: fields.state,
      zip: fields.zipcode,
      phone: fields.phonenumber + fields.phonenumber1 + fields.phonenumber2,
      email: fields.emailid,
      choice: selectCard,
      denomination: StorageService.getDenomination(),
      certificate: StorageService.getVoucher(),
      ans1: StorageService.getAns("ans1"),
      ans2: StorageService.getAns("ans2"),
      ans3: StorageService.getAns("ans3"),
    };

    try {
      const responseuserform = UserformService.userForm(data);
      responseuserform
        .then((response) => {
          isformIsValid = true;
          setValueUserform(response.data.status);

          StorageService.setUserformStorage();
          // localStorage.setItem("abc", JSON.stringify(a));
          // var user = JSON.parse(localStorage.getItem('abc'));
          // user.a
          secureLocalStorage.setItem(
            "UserForm",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phonenumber: fields.phonenumber,
              phonenumber1: fields.phonenumber1,
              phonenumber2: fields.phonenumber2,
              emailid: fields.emailid,
              card: selectCard,
              popup: response.data.data.popup,
            })
          );
          navigate("/redemption-thankyou");

          if (valueUserform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          isformIsValid = false;
          let errorData = {};
          errorData["name"] =
            err.response?.data?.message &&
            err.response?.data?.message?.fname &&
            err.response?.data?.message?.fname[0];
          errorData["lname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.lname &&
            err.response?.data?.message?.lname[0];
          errorData["address"] =
            err.response?.data?.message &&
            err.response?.data?.message?.address &&
            err.response?.data?.message?.address[0];
          errorData["city"] =
            err.response?.data?.message &&
            err.response?.data?.message?.city &&
            err.response?.data?.message?.city[0];
          errorData["state"] =
            err.response?.data?.message &&
            err.response?.data?.message?.state &&
            err.response?.data?.message?.state[0];
          errorData["zipcode"] =
            err.response?.data?.message &&
            err.response?.data?.message?.zip &&
            err.response?.data?.message?.zip[0];
          errorData["phonenumber"] =
            err.response?.data?.message &&
            err.response?.data?.message?.phone &&
            err.response?.data?.message?.phone[0];
          errorData["emailid"] =
            err.response?.data?.message &&
            err.response?.data?.message?.email &&
            err.response?.data?.message?.email[0];
          errorData["checkederrortwo"] =
            err.response?.data?.message &&
            err.response?.data?.message?.size &&
            err.response?.data?.message?.size[0];
          errorData["checkederrorfive"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          errorData["checkedboxerrornine"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          errorData["certificate"] =
            err.response?.data?.message &&
            err.response?.data?.message?.certificate &&
            err.response?.data?.message?.certificate[0];
          setErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      isformIsValid = false;
      let errorData = {};
      errorData["name"] = err.response.data.message.fname[0];
      errorData["lname"] = err.response.data.message.lname[0];
      errorData["emailid"] = err.response.data.message.phone[0];
      // errorData["address"] = err.response.data.message.address[0];
      // errorData["city"] = err.response.data.message.city[0];
      // errorData["state"] = err.response.data.message.state[0];
      // errorData["zipcode"] = err.response.data.message.zip[0];
      // errorData["phonenumber"] = err.response.data.message.phone[0];
      errorData["checkederrorfive"] = err.response.data.message.choice[0];
      errorData["checkedboxerrornine"] = err.response.data.message.choice[0];
      errorData["certificate"] = err.response.data.message.certificate[0];
      setErrors(errorData);
    }
    return isformIsValid;
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form">
            <h1>
              {imageInfo.name.replace(new RegExp("Virtual", "g"), "")}{" "}
              {process.env.REACT_APP_USERFORMTITLE}
            </h1>
            <h2>Contact Information</h2>
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    tabIndex="1"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Last Name"
                    tabIndex="1"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>
                <div className="clearfix"></div>

                {imageInfo.type == "physical" && (
                  <>
                    <div className="col-md-6">
                      <label required className="formsub">
                        Address <span className="red_point">*</span>
                      </label>
                      <input
                        type="text"
                        style={
                          errors.address && !errors.lname && !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        // name="address"
                        placeholder="Address"
                        name="address"
                        tabIndex="1"
                        id="address"
                        ref={
                          errors.address && !errors.lname && !errors.name
                            ? ref
                            : null
                        }
                        className="searchBox_deals vc-validate"
                        autoFocus=""
                        value={fields.address || ""}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">
                        {errors.address && !errors.lname && !errors.name
                          ? errors.address
                          : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="formsub">
                        City <span className="red_point">*</span>
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        style={
                          errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        tabIndex="4"
                        placeholder="City"
                        ref={
                          errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? ref
                            : null
                        }
                        value={fields.city || ""}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">
                        {errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? errors.city
                          : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="formsub">
                        State <span className="red_point">*</span>
                      </label>

                      <Select
                        classNamePrefix="react-select"
                        className={`select_1 dropdown ${
                          errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? "error-select-search"
                            : ""
                        }`}
                        placeholder="Select state"
                        options={states}
                        isSearchable={true}
                        isClearable={true}
                        name="state"
                        ref={
                          errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? ref
                            : null
                        }
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setFields((prevValue) => {
                              return {
                                ...prevValue,
                                ["state"]: selectedOption.value,
                              };
                            });
                          } else {
                            setFields((prevValue) => {
                              return {
                                ...prevValue,
                                ["state"]: "",
                              };
                            });
                          }
                        }}
                      />
                      <div className="errorMsg">
                        {errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? errors.state
                          : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="formsub">
                        Zip Code <span className="red_point">*</span>
                      </label>
                      <input
                        type="text"
                        id="zipcode"
                        style={
                          errors.zipcode &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        name="zipcode"
                        maxLength="10"
                        tabIndex="6"
                        placeholder="Zip/Postal Code"
                        value={fields.zipcode || ""}
                        ref={
                          errors.zipcode &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? ref
                            : null
                        }
                        onChange={handleChange}
                      />
                      <div className="errorMsg">
                        {errors.zipcode &&
                        !errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? errors.zipcode
                          : null}
                      </div>
                    </div>
                  </>
                )}

                {imageInfo.type == "virtual" && (
                  <div className="col-md-6">
                    <label className="formsub">
                      Email <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailid"
                      tabIndex="10"
                      ref={
                        errors.emailid && !errors.lname && !errors.name
                          ? ref
                          : null
                      }
                      style={
                        errors.emailid && !errors.lname && !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      // value=""
                      id="emailid"
                      placeholder="E-mail"
                      value={fields.emailid || ""}
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.emailid && !errors.lname && !errors.name
                        ? errors.emailid
                        : null}
                    </div>
                  </div>
                )}
                <br />
                <div className="alert alert-info">
                  Your personal information is fully encrypted. This information
                  is used only for Blood Assurance and will not be sold or used
                  for marketing purposes.
                </div>
                <h2>{process.env.REACT_APP_NAME}</h2>
                <div className="clearfix"></div>
                <div className="col-md-12" id="card-section">
                  <div className="row">
                    <span style={{ float: "left" }} id="color_msg22"></span>
                    <div className="errorMsg">{errors.checkederrorfive}</div>

                    {loading ? (
                      <>
                        <div
                          style={{ marginLeft: "50px", marginBottom: "10px" }}
                        >
                          <LoadingBox name="left" />{" "}
                        </div>
                      </>
                    ) : error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                      <>
                        <span
                          className="error_message_forimage"
                          id="color_msg22"
                        ></span>
                        <div className="check-boexs cen">
                          <div className="reward1">
                            <label>
                              <input
                                type="radio"
                                name="imgname"
                                id={imageInfo.id}
                                key={imageInfo.name}
                                value={imageInfo.name}
                                onChange={handleChangecheckboxfive}
                              />
                              <span className="insdex">{imageInfo.name}</span>
                              {imageInfo.image ? (
                                <img
                                  src={
                                    process.env.REACT_APP_API_IMAGES +
                                    imageInfo.image
                                  }
                                  className="imgcolor img-responsive"
                                />
                              ) : (
                                <div className="pirds pirds2">
                                  <span className="rdcrdtop">
                                    <table border="0">
                                      <tr>
                                        <td>{imageInfo.name}</td>
                                      </tr>
                                    </table>
                                  </span>
                                </div>
                              )}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <span style={{ color: "red" }} id="clr_msg"></span>
                </div>
                <span
                  className="error"
                  style={{ display: "none" }}
                  id="radio_msg"
                />
                <br></br>
                <div></div>
              </div>
              <label className="agree label">
                <input
                  type="checkbox"
                  id="myCheck"
                  name="checkbox"
                  className="conditions"
                  value={fields.checkbox}
                  onChange={handleChangecheckbox}
                />
                <span>I agree to</span>
                <Model />
                <br />
                <div className="errorMsg">{errors.agree}</div>
                <br />
              </label>
              <hr />
              <input
                // onclick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
                onClick={handleClick}
              ></input>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default UserForm;
